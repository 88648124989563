
import { defineComponent, ref, watch, onMounted } from "vue";
import { number, object, string } from "yup";
// import * as Yup from "yup";
import ApiService from "@/core/services/ApiService";
import { apiEndpoint } from "@/mixin/apiMixin";
import { VueCookieNext } from "vue-cookie-next";
import { ElNotification } from "element-plus";
import { useBus } from "@/bus.ts";
import {DrawerComponent} from "@/assets/ts/components";
import {useRoute} from "vue-router";

interface DistrictOption {
  value: string;
  text: string | undefined;
}

export default defineComponent({
  mixins: [apiEndpoint],
  name: "InnovativeTechnology",
  props: {
    data: { type: Object },
  },
  setup(props) {
    const route = useRoute();
    const { bus } = useBus();
    const formData = ref({
      id: "",
      updated_by: "",
      ia_partner_id: VueCookieNext.getCookie("_ia_partner_id") !== "null"
          ? VueCookieNext.getCookie("_ia_partner_id")
          : 0,
      created_by: VueCookieNext.getCookie("_user_id"),
      geo_division_id: "",
      geo_district_id: "",
      district_name: "",
      organization_name: "",
      facilities_provided: "",
      total_received_services: "",
      total_male: "",
      total_female: "",
      year: "",
      type: "",
    });

    watch(
        () => props.data,
        (newData) => {
          if (newData) {
            formData.value = {
              ...formData.value,
              ...newData,
            };
          }
        }
    );

    const schema = object().shape({
      facilities_provided: string().required("Facilities provided is required"),
    });

    const errors = ref({});
    const divisionOptions = ref([]);
    const districtOptions = ref<DistrictOption[]>([]);

    const getDivision = async () => {
      try {
        const response = await ApiService.get(
            apiEndpoint.data().VUE_APP_DIVISION_LIST
        );

        divisionOptions.value = response.data.data.map((option) => ({
          value: option.id,
          text: option.division_name_eng,
        }));
      } catch (error) {
        console.error("Error fetching component options:", error);
      }
    };

    const getDivisionWistDistrict = async () => {
      try {
        const response = await ApiService.post(
            apiEndpoint.data().VUE_APP_DISTRICT_LIST,
            {
              geo_division_id: formData.value.geo_division_id,
            }
        );
        districtOptions.value = response.data.data.map((option) => ({
          value: option.id,
          text: option.district_name_eng,
        }));
      } catch (error) {
        console.error("Error fetching component options:", error);
      }
    };

    watch(
        () => formData.value.geo_division_id,
        (newComponent) => {
          if (newComponent !== "") {
            getDivisionWistDistrict();
          }
        }
    );

    const save = async () => {
      try {
        if (formData.value.id) {
          formData.value.updated_by = VueCookieNext.getCookie("_user_id");
        }

        await schema.validate(formData.value, { abortEarly: false });

        const selectedDistrictText:string | undefined =
            districtOptions.value.find(
                (option) => option.value === formData.value.geo_district_id
            )?.text;

        if (selectedDistrictText) {
          formData.value.district_name = selectedDistrictText;
        }

        formData.value.type = route.params.type.toString();

        ApiService.post(
            apiEndpoint.data().VUE_APP_INNOVATIVE_TECHNOLOGY_STORE,
            formData.value
        )
            .then((response) => {
              console.log(response.data);
              if (response.data.status == "success") {
                ElNotification({
                  dangerouslyUseHTMLString: true,
                  message: response.data.data,
                  type: "success",
                });
                DrawerComponent.hideAll();
                bus.emit("loadData", true);
              } else {
                ElNotification({
                  dangerouslyUseHTMLString: true,
                  message: response.data.msg,
                  type: "error",
                });
              }
            })
            .catch(({ response }) => {
              console.log(response);
            });

        formData.value = {
          id: "",
          updated_by: "",
          ia_partner_id: VueCookieNext.getCookie("_ia_partner_id") !== "null"
              ? VueCookieNext.getCookie("_ia_partner_id")
              : 0,
          created_by: VueCookieNext.getCookie("_user_id"),
          geo_division_id: "",
          geo_district_id: "",
          district_name: "",
          organization_name: "",
          facilities_provided: "",
          total_received_services: "",
          total_male: "",
          total_female: "",
          year: "",
          type: "",
        };
        errors.value = {};
      } catch (validationErrors) {
        errors.value = validationErrors.inner.reduce((acc, error) => {
          acc[error.path] = error.message;
          return acc;
        }, {});
      }
    };

    onMounted(() => {
      getDivision();
    });

    return {
      divisionOptions,
      districtOptions,
      formData,
      errors,
      save,
    };
  },
});
