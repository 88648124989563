import { ref } from "vue";
import { format } from "date-fns";

const formatDate = ref((dateString,time='',year=false) => {
  if (year) {
    return dateString ? format(new Date(dateString), "yyyy") : "";
  } else if (time) {
    return dateString ? format(new Date(dateString), "dd/MM/yyyy HH:mm:ss") : "";
  } else {
    return dateString ? format(new Date(dateString), "dd/MM/yyyy") : "";
  }

});

const formatType = ref((type) => {
  return type ? type.replace(/_/g, ' ').replace(/\b\w/g, (char) => char.toUpperCase()) : "";
});

const months = ref([
  { value: 1, name: 'Jan' },
  { value: 2, name: 'Feb' },
  { value: 3, name: 'Mar' },
  { value: 4, name: 'Apr' },
  { value: 5, name: 'May' },
  { value: 6, name: 'Jun' },
  { value: 7, name: 'Jul' },
  { value: 8, name: 'Aug' },
  { value: 9, name: 'Sep' },
  { value: 10, name: 'Oct' },
  { value: 11, name: 'Nov' },
  { value: 12, name: 'Dec' }
]);

export { formatDate, formatType, months };
